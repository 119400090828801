@charset "utf-8";

// Set your brand colors
/* Coolors Exported Palette - coolors.co/597d5d-bec5ad-243e36-1c2541-a4b494 */
/* RGB */
$color1: rgba(89, 125, 93, 1);
$color2: rgba(190, 197, 173, 1);
$color3: rgb(47, 83, 72);
$color4: rgba(28, 37, 65, 1);
$color5: rgba(164, 180, 148, 1);
$font-family: 'Questrial', 'Roboto', sans-serif;

$family-primary: $font-family;

$primary: $color1;
$info: $color4;
$success: $color3;
$link: $color3;
$link-hover: $color4;

.is-link {
  color: #2f5348;
  cursor: pointer;
  text-decoration: none;
  border: 0px;
  font-weight: 700;
  font-size: 16px;
}
.is-link:hover {
  color: $color4;
}

.is-paddingless-vertical {
  padding: 0rem 1.5rem 1rem !important;
}

.largeText {
  font-size: 5rem !important;
}

@import '../../node_modules/bulma/bulma.sass';

// @import '../../node_modules/bulma/sass/utilities/_all.sass';
// @import '../../node_modules/bulma/sass/base/_all.sass';
// @import '../../node_modules/bulma/sass/elements/button.sass';
// @import '../../node_modules/bulma/sass/elements/container.sass';
// @import '../../node_modules/bulma/sass/elements/form.sass';
// @import '../../node_modules/bulma/sass/elements/title.sass';
// @import '../../node_modules/bulma/sass/components/navbar.sass';
// @import '../../node_modules/bulma/sass/layout/hero.sass';
// @import '../../node_modules/bulma/sass/layout/section.sass';
