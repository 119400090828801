@import '_style.scss';
@import '_error.scss';
@import '_menu.scss';
@import '_layout.scss';
@import '_form.scss';
@import '_dataView.scss';
@import '_animations.scss';

html,
body,
#app {
  height: 100%;
}

#app {
  min-height: 100%;
}

.footer {
  margin-top: -12px;
}
