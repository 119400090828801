.button {
  border-radius: 0% !important;
}

.no-rounded-corner {
  border-radius: 0% !important;
}

dl {
  margin: 0 0 2em 0;

  dt {
    display: block;
    font-weight: bold;
    margin: 0 0 (2em * 0.5) 0;
  }

  dd {
    margin-left: 2em;
  }

  &.alt {
    dt {
      display: block;
      width: 3em;
      margin: 0;
      clear: left;
      float: left;
    }

    dd {
      margin: 0 0 0.85em 5.5em;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}