.fadeInContainer {
  min-height: 10rem;
}

.banner-background-img::before {
  content: "";
  background: url("../images/background-logo.png") center center;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.05;
}

.survivor-banner-background-img::before {
  content: "";
  background: url("../images/Survivor-header-1024x575.jpg") center center;
  background-size: cover;
  background-position: 10% 0;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.nascar-banner-background-img::before {
  content: "";
  background: url("../images/nascar-banner.jpg") center center;
  background-size: cover;
  background-position: 10% 0;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.esl-banner-background-img::before {
  content: "";
  background: url("../images/esl-main.jpg") center center;
  background-size: cover;
  background-position: 10% 0;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.team-banner-background-img::before {
  content: "";
  background: 
  linear-gradient(
    rgba(0, 0, 0, 0.45), 
    rgba(0, 0, 0, 0.45), 
  ), url("../images/nascar-header-team-1024x576.jpg") center center;
  background-size: cover;
  background-position: 10% 0;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.5;
}

.contact-banner-background-img::before {
  content: "";
  background: 
  linear-gradient(
    rgba(0, 0, 0, 0.45), 
    rgba(0, 0, 0, 0.45), 
  ), url("../images/top-cover-1024x696.jpg") center center;
  background-size: cover;
  background-position: 10% 0;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.5;
}

.digital-banner-background-img::before {
  content: "";
  background: url("../images/apple-tv-3gen-home-screen.jpg") center center;
  background-size: cover;
  background-position: 0 0;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.digital-banner-background-img-connected::before {
  content: "";
  background: url("../images/ConnectedTV-1024x576.jpg") center center;
  background-size: cover;
  background-position: 0 0;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.profile-card {
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
}

.hide-me  {
  opacity: 0;
  display: flex;
}

.remove-me  {
  opacity: 0;
  display: none;
}

dl {
  margin: 0 0 2em 0;
}